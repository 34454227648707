import React from 'react';

import { Router } from 'react-router';

import { createBrowserHistory } from 'history';

import { TypeApplicationStore } from '../stores/application-store';
import { injectStores } from '../stores/factories/store-utils';

import Footer from '../components/menus/footer/footer';

import './jakhub.scss';

const history = createBrowserHistory();

export interface Props {
    ApplicationStore: TypeApplicationStore;
}

function Jakhub(props: Props) {
    const {
        ApplicationStore: { applicationIsLoaded },
    } = props;

    if (!applicationIsLoaded()) {
        return <div />;
    }

    return (
        <Router history={history}>
            <Footer />
        </Router>
    );
}

export default injectStores(['ApplicationStore'])(Jakhub);
