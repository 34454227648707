import React from 'react';

import { Provider } from 'mobx-react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@material-ui/core/styles';

import JakhubHeader from './app/jakhub-header';
import JakhubFooter from './app/jakhub-footer';
import AppTheme from './app/app-theme';

import ApplicationStore from './stores/application-store';
import ContentStore from './stores/content-store';
import ProfileStore from './stores/profile-store';
import ProfileEntityStore from './stores/profile-entity-store';

import { preLoadAppResources } from './app/app_initiatisation';

import './jakhub.scss';

import { HEADER_TOOLS } from './constants';

import './jakhub.scss';

preLoadAppResources();

const { SIGN_IN } = HEADER_TOOLS;

const rootElement = document.getElementById('root');
const footerElement = document.getElementById('footer-root');

ReactDOM.render(
    <Provider
        ApplicationStore={ApplicationStore}
        ContentStore={ContentStore}
        ProfileStore={ProfileStore}
        ProfileEntityStore={ProfileEntityStore}
    >
        <ThemeProvider theme={AppTheme}>
            <JakhubHeader />
        </ThemeProvider>
    </Provider>,
    rootElement
);

ReactDOM.render(
    <Provider
        ApplicationStore={ApplicationStore}
        ContentStore={ContentStore}
        ProfileStore={ProfileStore}
    >
        <ThemeProvider theme={AppTheme}>
            <JakhubFooter />
        </ThemeProvider>
    </Provider>,
    footerElement
);

rootElement?.addEventListener(
    'closeHeaderDropdowns',
    () => {
        ApplicationStore.hideAllHeaderTools();
    },
    false
);

rootElement?.addEventListener(
    'promptLogIn',
    () => {
        ApplicationStore.showToolInHeader(SIGN_IN);
    },
    false
);

rootElement?.classList.add('jakhub-header');
footerElement?.classList.add('jakhub-footer');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
